/* The above code is a TypeScript React component named `SocialMedia` that fetches and combines data
from Facebook and Instagram APIs to display in a social media carousel. */
'use client';
import { Container } from '@mui/material';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import React, { useCallback, useEffect, useState } from 'react';
import { ICmsImage } from '@bayada/interfaces';
import { SocialMediaCarousel } from './social-media-carousel';

export interface InstaData {
  instaToken: string | undefined;
  instaPlatformName: string | undefined;
  instaIcon: ICmsImage | null;
  pageId: string | undefined;
}

export interface FbData {
  fbToken: string | undefined;
  pageId: string | undefined;
  fbPlatformName: string | undefined;
  fbIcon: ICmsImage | null;
}
export interface CarouselData {
  internalName: string;
  carouselItemCount: number;
  hashtags: string[];
}
export type FbAndInstaData = {
  instaData: InstaData;
  fbData: FbData;
  carouselItemNo: CarouselData;
} | null;

export interface Response {
  data: FaceBookData[] | InstagramData[];
  paging: unknown;
}

export interface FaceBookData {
  full_picture: string;
  message?: string;
  created_time: string;
  id: string;
  likes?: string;
}

export interface InstagramData {
  id: string;
  caption: string;
  media_url: string;
  media_type: string;
  timestamp: string;
  username: string;
  permalink: string;
}

export interface CombinedData {
  id: string;
  full_picture?: string;
  message?: string;
  created_time?: string;
  likes?: string;
  caption?: string;
  media_url?: string;
  thumbnail_url?: string;
  post_url?: string;
  media_type?: string;
  timestamp?: string;
  username?: string;
  permalink?: string;
  platform: string | undefined;
  icon: ICmsImage | null | undefined;
  pageName?: string | null;
  profilePicture?: string | null;
}

export const formatDateToLocal = (dateStr: string, locale = 'en-US') => {
  const date = new Date(dateStr);
  const currentDate = new Date();
  const timeDifference = Math.abs(currentDate.getTime() - date.getTime());
  const daysAgo = Math.floor(timeDifference / (1000 * 3600 * 24));

  switch (daysAgo) {
    case 0:
      return 'Today';
    case 1:
      return 'Yesterday';
    default:
      return `${daysAgo} days ago`;
  }
};

// Type guard to check if the post is of type InstagramData
function isInstagramData(
  post: InstagramData | FaceBookData
): post is InstagramData {
  return 'caption' in post && 'timestamp' in post && 'media_type' in post; // Instagram-specific properties
}

/**
 * Filters a list of posts based on provided hashtags.
 *
 * @param posts - The list of posts to filter (each post contains a caption).
 * @param hashtags - The list of hashtags to check for.
 * @returns The filtered list of posts that contain at least one of the provided hashtags.
 */
function filterPostsByHashtags(
  posts: InstagramData[],
  hashtags: string[]
): InstagramData[] {
  if (hashtags.length === 0) {
    // If no hashtags are provided, return the posts as is.
    return posts;
  }

  return posts.filter((post) => {
    // Check if the post's caption contains any of the hashtags
    return hashtags.some((hashtag) => post.caption?.includes(hashtag));
  });
}

/**
 * The `SocialMedia` function in TypeScript React fetches and combines data from Facebook and Instagram
 * APIs to display in a carousel component.
 * @param {FbAndInstaData} props - The `SocialMedia` component takes in a prop object `props` with the
 * following properties:
 * @returns The `SocialMedia` component is being returned. It fetches data from Facebook and Instagram
 * APIs, combines the data, sorts it based on timestamp, and then displays it in a carousel using the
 * `SocialMediaCarousel` component.
 */
export function SocialMedia(props: FbAndInstaData) {
  const { fbData, instaData, carouselItemNo } = props || {};
  const facebookToken = fbData?.fbToken;
  const instagramToken = instaData?.instaToken;
  const pageId = fbData?.pageId || instaData?.pageId;
  const facebookPlatformName = fbData?.fbPlatformName;
  const instagramPlatformName = instaData?.instaPlatformName;
  const fbIcon = fbData?.fbIcon;
  const instaIcon = instaData?.instaIcon;
  const carouselItemLimit = carouselItemNo?.carouselItemCount ?? 12;
  const [socData, setSocData] = useState<CombinedData[]>([]);
  const hashtags = carouselItemNo?.hashtags;

  const fetchInstagramData = useCallback(() => {
    try {
      if (!instagramToken) {
        console.log('instagramToken is not available');
        return null;
      }
      const instaGramResponce = fetch(
        `https://graph.facebook.com/v16.0/${pageId}/media?fields=id,caption,media_type,media_url,thumbnail_url,timestamp,username,permalink&limit=100&access_token=${instagramToken}`
      );
      return instaGramResponce;
    } catch (error) {
      console.log(instagramToken, ' ::: fetchInstagramData Error');
    }
  }, [carouselItemLimit, instagramToken]);

  const fetchFacebookData = useCallback(() => {
    try {
      if (!facebookToken) {
        console.log('facebookToken is not available');
        return null;
      }
      const facebookResponse = fetch(
        `https://graph.facebook.com/${pageId}/feed?fields=full_picture,attachments,message,likes,created_time&limit=${carouselItemLimit}&access_token=${facebookToken}`
      );
      return facebookResponse;
    } catch (error) {
      console.log(facebookToken, ' ::: fetchFacebookData Error');
    }
  }, [carouselItemLimit, facebookToken, pageId]);

  const fetchFbProfileData = useCallback(() => {
    try {
      if (!facebookToken) {
        console.log('facebookToken is not available');
        return null;
      }
      const profileResponce = fetch(
        `https://graph.facebook.com/${pageId}?fields=id,picture,name&access_token=${facebookToken}`
      );
      return profileResponce;
    } catch (error) {
      console.log(facebookToken, ' ::: fetchFbProfileData Error');
    }
  }, [facebookToken, pageId]);

  const socialMediaData = useCallback(async () => {
    try {
      const [instaResponse, facebookResponse, facebookProfileResponse] =
        await Promise.all([
          fetchInstagramData(),
          fetchFacebookData(),
          fetchFbProfileData()
        ]);

      const instagramResponseJson: Response = await instaResponse?.json();
      const facebookResponseJson: Response = await facebookResponse?.json();
      const profileData = await facebookProfileResponse?.json();

      const combinedData: CombinedData[] = [];
      let instagramData: InstagramData[] = [];

      // some where down here we need to only retun the filtered posts if hashtags are set
      // Instagram Filtering process
      if (hashtags && hashtags?.length > 0) {
        if (
          Array.isArray(instagramResponseJson?.data) &&
          instagramResponseJson?.data?.every(isInstagramData)
        ) {
          instagramData = filterPostsByHashtags(
            instagramResponseJson?.data,
            hashtags
          );
        }
      }

      facebookResponseJson?.data?.forEach((item) => {
        combinedData?.push({
          ...item,
          platform: facebookPlatformName,
          icon: fbIcon,
          pageName: profileData?.name,
          profilePicture: profileData?.picture?.data?.url,
          post_url: `https://facebook.com/${item?.id}`
        });
      });

      if (instagramData?.length > 0) {
        instagramData.forEach((item) => {
          combinedData?.push({
            ...item,
            platform: instagramPlatformName,
            profilePicture: profileData?.picture?.data?.url,
            icon: instaIcon
          });
        });
      } else {
        instagramResponseJson?.data?.forEach((item) => {
          combinedData?.push({
            ...item,
            platform: instagramPlatformName,
            profilePicture: profileData?.picture?.data?.url,
            icon: instaIcon
          });
        });
      }

      combinedData.sort((a, b) => {
        const timestampA = new Date(
          a?.timestamp || a?.created_time || ''
        )?.getTime();
        const timestampB = new Date(
          b?.timestamp || b?.created_time || ''
        )?.getTime();
        return timestampB - timestampA;
      });
      setSocData(combinedData.slice(0, carouselItemLimit));
    } catch (error) {
      console.error(error);
    }
  }, [
    carouselItemLimit,
    facebookPlatformName,
    fbIcon,
    fetchFacebookData,
    fetchFbProfileData,
    fetchInstagramData,
    instaIcon,
    instagramPlatformName
  ]);

  useEffect(() => {
    socialMediaData();
  }, [socialMediaData]);

  return (
    <div>
      {socData?.length > 0 && (
        <div className="py-10 sm:py-12 md:py-14 overflow-hidden">
          <Container fixed className="relative mx-auto">
            <SocialMediaCarousel socialMediaItems={socData} />
          </Container>
        </div>
      )}
    </div>
  );
}
