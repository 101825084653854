'use client';
import { useRouter } from 'next/navigation';
import { serviceDataProps } from '../find-an-office-page';
import { useAppContext } from '../../../context/app-context';
import { getNestedSlug, trimSpaces } from 'apps/bayada/utils/helper';
import { getServicePageSlug } from 'apps/bayada/services/service-helper';

export type serviceLinkProps = {
  speciality: serviceDataProps;
  className: string;
};
/**
 * This component provides a reusable way to create links to service pages, with built-in fetching of page data from Contentful CMS.
 * It handles different navigation methods based on the browser and provides flexibility for styling through the className prop.
 */
const ServiceLink = (props: serviceLinkProps) => {
  const { speciality, className } = props || {};
  const router = useRouter();
  const { context } = useAppContext();
  const { userAgent } = context || {};
  const goToServicePage = async (service: serviceDataProps) => {
    const serviceSlug = getServicePageSlug(trimSpaces(service?.fullName));
    const contentful = context?.ctfClient;
    const servicePage = await contentful?.getEntries({
      content_type: 'page',
      'fields.slug': serviceSlug,
      include: 10
    });

    const servicePageSlug = servicePage?.items?.[0]
      ? getNestedSlug(servicePage?.items?.[0])
      : serviceSlug;
    if (servicePageSlug) {
      if (userAgent === 'Firefox' && window) {
        window.location.href = `/${servicePageSlug}`;
      } else {
        router.push(`/${servicePageSlug}`);
      }
    }
  };

  return (
    <div className={'text-right'}>
      <a
        tabIndex={0}
        aria-label={speciality?.fullName}
        className={className}
        onClick={() => goToServicePage(speciality)}
      >
        {speciality?.fullName}
      </a>
    </div>
  );
};

export default ServiceLink;
