/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import React from 'react';
import { Divider } from '@mui/material';
import { CmsImage, Icon } from '@bayada/shared/ui-components';
import VisitOfficeButton from 'apps/bayada/components/office-page/visit-office-button';
import { handleDirections } from 'apps/bayada/components/office-page/office-page-helper';
import { useRouter } from 'next/navigation';
import { CmsRichText } from 'apps/bayada/components/cms-rich-text/cms-rich-text';
import {
  findAnOfficePageProps,
  serviceDataProps
} from '../find-an-office-page';
import { OfficePageProps } from 'apps/bayada/components/office-page/office-page';
import { OfficeModel } from '../office-data-parser';
import { useAppContext } from '../../../context/app-context';
import ServiceLink from './service-link';
import { ServiceProps } from '@bayada/interfaces';
import { getServiceArray } from 'apps/bayada/services/service-helper';
import { trimCommasAndSpaces } from 'apps/bayada/utils/helper';
import { bayadaOfficeLocationTitle } from 'apps/bayada/constants';

export interface officesListProps {
  isFindAnOfficePage?: boolean;
  officeData?: any;
  loading?: boolean;
  officePageData?: findAnOfficePageProps | null;
  selectedService?: serviceDataProps;
  offices?: any;
  serviceList?: ServiceProps[] | null;
  localOfficePage?: OfficePageProps | null;
  isJv?: boolean;
  jvService?: ServiceProps;
}

/**
 * Constructs a formatted address string from the given office object.
 *
 * This function combines various components of the office's mailing address into a single, formatted string. It concatenates the address lines, city, state, and ZIP code, ensuring that commas and spaces are trimmed from each component. The address components are added in the following order: mailing address line 1, mailing address line 2 (if present), city, state, and ZIP code.
 *
 * @param office - An object representing the office, which includes optional properties for the mailing address components:
 *   - `mailingAddress1`: The first line of the office's mailing address.
 *   - `mailingAddress2`: The second line of the office's mailing address (if applicable).
 *   - `mailingAddressCity`: The city of the office's mailing address.
 *   - `mailingAddressState`: The state of the office's mailing address.
 *   - `mailingAddressZipCode`: The ZIP code of the office's mailing address.
 *
 * @returns A string representing the formatted mailing address. The components are concatenated with commas separating them. If a component is missing, it is skipped. The final address string will not end with a trailing comma.
 *
 * The function uses `trimCommasAndSpaces` to clean up each address component by removing leading and trailing commas and spaces.
 */
export const renderOfficeAddress = (office: any) => {
  return (
    (office?.mailingAddress1
      ? trimCommasAndSpaces(office?.mailingAddress1) + ', '
      : '') +
    (office?.mailingAddress2
      ? trimCommasAndSpaces(office?.mailingAddress2) + ', '
      : '') +
    (office?.mailingAddressCity
      ? trimCommasAndSpaces(office?.mailingAddressCity) + ', '
      : '') +
    (office?.mailingAddressState
      ? trimCommasAndSpaces(office?.mailingAddressState) + ', '
      : '') +
    (office?.mailingAddressZipCode
      ? trimCommasAndSpaces(office?.mailingAddressZipCode)
      : '')
  );
};

/**
 * Opens the default mail client with a pre-filled email to share office location details.
 * @param {any} officeLoc - The office location object containing details like name, address, phone, etc.
 */
export const shareByMailClick = (officeLoc: any) => {
  const subject = encodeURIComponent(
    `${officeLoc?.name} ${bayadaOfficeLocationTitle}`
  );
  const body = encodeURIComponent(
    `${officeLoc?.name || ''}\n${officeLoc?.mailingAddress1 || ''}\n${officeLoc?.mailingAddress2 || ''}\n${officeLoc?.mailingAddressCity || ''}, ${officeLoc?.mailingAddressState || ''} ${officeLoc?.mailingAddressZipCode || ''}${officeLoc?.phone ? `\nPhone: ${officeLoc.phone.replace(/\D/g, '')?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}` : ''}`
  );
  const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;
  if (window) {
    window.open(mailtoUrl, '_blank');
  }
};

/**
 * Opens the default phone app to make a call.
 * @param {string} phone - The phone number to call.
 */
export const handlePhoneClick = (phone: string) => {
  if (window) {
    window.open(`tel:${phone}`, '_self');
  }
};

/**
 * Constructs a URL route string based on the provided office details and optional service.
 *
 * The function creates a formatted URL path for an office location, incorporating the office's mailing address and an optional service.
 * The URL is constructed to be lowercased, sanitized (removing non-alphanumeric characters), and formatted with hyphens for spaces.
 *
 * @param office - An object representing the office, which includes various address components and an `officeId`:
 *   - `mailingAddressState`: The state portion of the office's mailing address.
 *   - `mailingAddressCity`: The city portion of the office's mailing address.
 *   - `mailingAddress1`: The primary street address.
 *   - `mailingAddress2`: An additional street address (if applicable).
 *   - `officeId`: A unique identifier for the office.
 * @param service - An optional string representing a service associated with the office. This will be included in the route if provided.
 *
 * @returns A string representing the constructed URL route. The format of the route is:
 *   - `/service/locations/state/city/street/officeId`
 *
 * The service and address components are formatted to be URL-friendly:
 * - Leading and trailing spaces are removed.
 * - All characters are converted to lowercase.
 * - Non-alphanumeric characters are replaced with spaces.
 * - Spaces are replaced with hyphens.
 */
export const constructOfficePageRoute = (office: any, service?: string) => {
  const _service = service
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/g, ' ')
    ?.replace(/\s/g, '-');
  const _state = office?.mailingAddressState
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');

  const _city = office?.mailingAddressCity
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');

  const _street = (
    office?.mailingAddress1?.trim() +
    ' ' +
    office?.mailingAddress2?.trim()
  )
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');

  const route = `/${_service}/locations/${_state}/${_city}/${_street}/${office?.officeId}`;
  return route;
};

/**
 * This component is designed to be flexible, handling both a "Find an Office" page layout and a general office listing layout.
 * It integrates with various services and utilities to provide a comprehensive office information display with interactive elements.
 */
const OfficesList = (props: officesListProps) => {
  const {
    officeData,
    officePageData,
    loading,
    selectedService,
    offices,
    serviceList,
    localOfficePage,
    isFindAnOfficePage,
    isJv,
    jvService
  } = props || {};
  const router = useRouter();
  const { context } = useAppContext();
  const { userAgent } = context || {};

  const onOfficeClick = (office: OfficeModel) => {
    if (office && selectedService) {
      const route = constructOfficePageRoute(office, selectedService?.fullName);
      if (userAgent === 'Firefox') {
        window.location.href = route;
      } else {
        router.push(route);
      }
    }
  };

  return (
    <>
      {isFindAnOfficePage ? (
        <>
          {officeData?.length ? (
            <div className="flex lg:w-4/5 w-full flex-col  gap-10 ">
              {officeData?.map((office: any, index: number) => {
                office.serviceArr = getServiceArray(
                  office,
                  officePageData?.services
                );
                return (
                  <div key={index} className="flex flex-col items-start gap-4">
                    <a
                      onClick={() => onOfficeClick(office)}
                      tabIndex={0}
                      className={`t-18-21 font-frutiger mb-1 cursor-pointer font-bold color-ba-primary-red underline-offset-2 outline-offset-1`}
                      aria-label="find-an-office-name"
                      itemProp="name"
                    >
                      {office?.name}
                    </a>
                    <h2
                      className="t-14-15 font-normal color-ba-gray-900 mb-0"
                      aria-label="find-an-office-address"
                      itemType="PostalAddress"
                    >
                      <span itemProp="streetAddress">
                        {office?.mailingAddress1
                          ? trimCommasAndSpaces(office?.mailingAddress1) + ', '
                          : ''}
                      </span>
                      <span itemProp="streetAddress">
                        {office?.mailingAddress2
                          ? trimCommasAndSpaces(office?.mailingAddress2) + ', '
                          : ''}
                      </span>
                      <span itemProp="addressLocality">
                        {office?.mailingAddressCity
                          ? trimCommasAndSpaces(office?.mailingAddressCity) +
                            ', '
                          : ''}
                      </span>
                      <span itemProp="addressRegion">
                        {office?.mailingAddressState
                          ? trimCommasAndSpaces(office?.mailingAddressState) +
                            ', '
                          : ''}
                      </span>
                      <span itemProp="postalCode">
                        {trimCommasAndSpaces(office?.mailingAddressZipCode)}
                      </span>
                    </h2>
                    <div
                      className="flex justify-between flex-col sm:flex-row sm:items-center gap-3 align-middle sm:py-3 sm:border-b sm:border-t sm:border-solid border-ba-gray-175 w-full"
                      aria-label="phone-container"
                    >
                      <div itemProp="Contact Point">
                        {office?.phone && (
                          <span
                            className="flex gap-2 t-14-15 items-center"
                            onClick={() => handlePhoneClick(office?.phone)}
                          >
                            <Icon
                              iconName="call"
                              className="svg-icon icon-24 flex items-center justify-center empty:hidden"
                            ></Icon>
                            <span className="cursor-pointer">
                              {('' + office?.phone)
                                .replace(/\D/g, '')
                                .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="flex gap-5  border-b  border-t border-solid border-[#D6D6D6] sm:!border-transparent py-3 sm:py-0">
                        <span
                          className="t-13 color-ba-gray-425"
                          aria-label="distance"
                        >
                          {office?.distance?.toFixed(2)} miles
                        </span>
                        <a
                          className="t-13 flex gap-1 outline-offset-1 underline-offset-2"
                          aria-label="get-directions-container"
                          tabIndex={0}
                        >
                          <Icon
                            iconName="locationPin"
                            className="svg-icon icon-16 flex items-center justify-center"
                          ></Icon>
                          <span
                            className="color-ba-primary-red underline-offset-2 underline cursor-pointer text-primary-shade"
                            onClick={() => handleDirections(office)}
                          >
                            {officePageData?.getDirectionsLabel}
                          </span>
                        </a>
                        <a
                          className="t-13 flex gap-1 lg:hidden outline-offset-1 underline-offset-1"
                          aria-label="email-container"
                          tabIndex={0}
                        >
                          <Icon
                            iconName="mail"
                            className="svg-icon icon-20 flex items-center justify-center"
                          ></Icon>
                          <span
                            className="color-ba-primary-red text-primary-shade underline-offset-2 underline"
                            onClick={() => shareByMailClick(office)}
                          >
                            {officePageData?.shareViaEmailLabel}
                          </span>
                        </a>
                      </div>
                    </div>
                    <VisitOfficeButton
                      buttonVarient="contained"
                      color="primary"
                      office={office}
                      service={selectedService?.fullName}
                      text={officePageData?.visitOfficeButtonText ?? ''}
                    />
                    <div className="flex items-start justify-between flex-row gap-3 sm:py-3 w-full">
                      <div className="flex flex-col gap-2 t-13">
                        <div className="flex gap-2 t-13 font-semibold color-ba-gray-425">
                          {officePageData?.serviceAreasLabel}
                        </div>
                        <div className="color-ba-gray-425 t-13 flex gap-2 gap-y-2 flex-wrap empty:hidden">
                          {office?.serviceAreas}
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 t-13 items-end">
                        <span
                          className=" color-ba-gray-425 font-semibold"
                          aria-label="services-offered"
                        >
                          {officePageData?.servicesOfferedLabel}
                        </span>
                        <div className="flex gap-5 gap-y-3 flex-wrap ml-1 justify-end">
                          {office?.serviceArr?.map(
                            (speciality: serviceDataProps, index: number) => {
                              return (
                                <div
                                  key={index}
                                  className="flex gap-1 items-end color-ba-primary-black"
                                  aria-label="image-container"
                                >
                                  {speciality?.icon && (
                                    <CmsImage
                                      {...speciality?.icon}
                                      width={20}
                                      height={20}
                                    />
                                  )}
                                  <ServiceLink
                                    className={
                                      'underline-offset-2 ml-1 underline text-primary-shade cursor-pointer outline-offset-2 text-right'
                                    }
                                    speciality={speciality}
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>

                    {index !== officeData?.length - 1 && (
                      <Divider
                        orientation="horizontal"
                        className="border-ba-gray-450 !mt-6 w-full"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          ) : null}{' '}
          {!officeData?.length && !loading ? (
            <div className="border h-full border-solid rounded-lg border-ba-gray-175 flex flex-col gap-3 items-center text-center justify-center min-h-[260px] p-10">
              <Icon
                iconName="noDataSearchBar"
                className="svg-icon h-[80px] mx-4 flex items-center justify-center"
              ></Icon>
              {officePageData?.noLocationsFound && (
                <CmsRichText value={officePageData?.noLocationsFound} />
              )}
            </div>
          ) : null}
        </>
      ) : (
        <>
          {offices?.map((office: any, officeIndex: number) => {
            const servArr = getServiceArray(office, serviceList);

            return (
              <React.Fragment key={officeIndex}>
                <div
                  className="t-18-21  font-frutiger font-bold  col-span-12 lg:col-span-3"
                  aria-label="office-name-container"
                >
                  {office?.name}
                </div>
                <div className="col-span-12  sm:col-span-6 lg:col-span-3">
                  <span
                    className="t-14-15 mb-1 "
                    aria-label="office-address-container"
                  >
                    {renderOfficeAddress(office)}
                  </span>
                  <a
                    tabIndex={0}
                    className="flex items-center gap-2 outline-offset-2 underline-offset-2"
                    aria-label="get-directions-container"
                  >
                    <Icon
                      iconName="locationPin"
                      className="svg-icon icon-16 flex items-center justify-center"
                    ></Icon>
                    <span
                      className={`${isJv ? `color-ba-blue-600` : `text-primary-shade`} t-13 cursor-pointer underline-offset-2 underline`}
                      onClick={() => handleDirections(office)}
                    >
                      {localOfficePage?.getDirectionsLabel}
                    </span>
                  </a>
                </div>
                <div
                  className=" col-span-12  align-left lg:col-span-2  sm:hidden lg:block"
                  aria-label="phone-container"
                >
                  {office?.phone && (
                    <span
                      className="flex items-end gap-1 cursor-pointer t-15"
                      onClick={() => handlePhoneClick(office?.phone)}
                    >
                      <Icon
                        iconName="call"
                        className="svg-icon icon-24 flex items-center "
                      ></Icon>
                      <span className="leading-[18px]">
                        {office?.phone
                          ?.replace(/\D/g, '')
                          ?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                      </span>
                    </span>
                  )}
                </div>
                <div className="col-span-12 sm:col-span-6 sm:col-start-7 lg:col-span-2 flex flex-col gap-2  t-15">
                  <span
                    className=" color-ba-gray-425"
                    aria-label="services-offered"
                  >
                    {localOfficePage?.servicesOfferedLabel}
                  </span>
                  <div className="flex gap-4 flex-wrap">
                    {servArr?.map(
                      (service: serviceDataProps, index: number) => (
                        <div
                          className="flex items-center gap-2 "
                          key={service?.masterDataId}
                          aria-label="services-offered-text"
                        >
                          {service?.icon && (
                            <CmsImage
                              {...service?.icon}
                              width={24}
                              height={24}
                            />
                          )}
                          <ServiceLink
                            className={`${isJv ? `color-ba-blue-600` : `text-primary-shade`} underline underline-offset-2 cursor-pointer t-13`}
                            speciality={service}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                {office?.phone && (
                  <div className="col-span-12 sm:block hidden lg:hidden">
                    <span
                      className="flex  items-center gap-1 cursor-pointer t-15"
                      onClick={() => handlePhoneClick(office?.phone)}
                    >
                      <Icon
                        iconName="call"
                        className="svg-icon icon-24 flex items-center "
                      ></Icon>
                      <span>
                        {office?.phone
                          ?.replace(/\D/g, '')
                          ?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                      </span>
                    </span>
                  </div>
                )}
                <div
                  className="col-span-12 sm:col-span-6 lg:col-span-2"
                  aria-label="visit-office"
                >
                  <VisitOfficeButton
                    buttonVarient="contained"
                    color="primary"
                    office={office}
                    service={
                      !isJv
                        ? localOfficePage?.service?.fullName
                        : jvService?.fullName
                    }
                    text={localOfficePage?.visitOfficeButtonText ?? ''}
                    isJv={isJv}
                  />
                </div>
                <Divider
                  orientation="horizontal"
                  sx={{
                    marginTop: '30px',
                    marginBottom: '30px'
                  }}
                  className="col-start-1 col-end-13"
                />
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

export default OfficesList;
