/* eslint-disable @typescript-eslint/no-explicit-any */
import { Asset, AssetLink } from 'contentful';
import { ComponentMapTypes } from '../mapping';
import invoke from 'lodash/invoke';
import { TextAndItemProps } from '../components/text-and-item/text-and-item';
import { TextBlockProps } from '../components/text-block/text-block';
import {
  type CtaButtonProps,
  type SeperatorBlockProps,
  type SocialMediaProps,
  type CmsVideoProps,
  AlertProps,
  DonateCtaProps
} from '@bayada/shared/ui-components';
import { MenuItemGroupProps } from 'apps/bayada/components/menu-item-group/menu-item-group';
import { CmsImageProps, ContentImage, ServiceProps } from '@bayada/interfaces';
import { BannerProps } from '../components/banner/banner';
import { PrimaryNavigationProps } from '../components/primary-navigation/primary-navigation';
import { NavigationHeaderProps } from '../components/navigation-header/navigation-header';
import {
  LabelAndValueProps,
  StatsContainerProps
} from '../components/stats-container/stats-container';
import { ImageAndItemProps } from '../components/image-and-item/image-and-item';
import { ItemItemHeadingProps } from '../components/item-item-heading/item-item-heading';
import { HomeHeroBannerProps } from '../components/banner/home-hero-banner';
import { NavigationLinkProps } from '../components/navigation-link/navigation-link';
import { TopNavigationProps } from '../components/navigation-top/top-navigation';
import { QuoteProps } from '../components/quote/quote';
import { CmsTabProps, TabItemProps } from '../components/cms-tab/cms-tab';
import { BioPageProps } from '../components/bio-page/bio-page';
import { PeopleItemProps, PeoplePageProps } from '../components/people/people';
import { TestimonialProps } from '../components/testimonial/testimonial';
import { TextCardProps } from '../components/text-card/text-card';
import {
  AccordionAlignment,
  AccordionBehavior,
  AccordionContainerProps,
  AccordionItem
} from '../components/accordion-container/accordion-container';
import { FooterPageProps } from '../components/footer/footer';
import { isValidUSPhoneNumber, uuidv4 } from './helper';
import { TextAndImageProps } from '../components/text-and-image/text-and-image';
import { HubSpotFormProps } from '../components/hub-spot-form/cms-hub-spot-form';
import { ShareBlockProps } from '../components/share-block/share-block';
import { RequestServiceProps } from '../components/request-service/request-service';
import {
  CarouselContainerProps,
  CarouselItem
} from '../components/static-carousel-container/static-carousel-container';
import {
  CarouselData,
  FbAndInstaData,
  FbData,
  InstaData
} from '../components/social-media-carousel/social-media';
import { FindCareProps } from '../components/find-care/find-care';
import {
  findAnOfficePageProps,
  serviceDataProps
} from '../app/find-an-office/find-an-office-page';
import { Metadata } from 'next';
import { ContentGridProps } from '../components/content-grid/content-grid';
import { type NotFoundProps } from '../components/404/not-found';
import { OfficePageProps } from '../components/office-page/office-page';

export const componentDataPareserMap: { [key in ComponentMapTypes]?: any } = {
  textAndItem: parseTextAndItemData,
  textBlock: parseTextBlockData,
  separatorBlock: parseSeparatorBlockData,
  image: parseCmsImage,
  video: parseCmsVideo,
  cta: parseCtaData,
  socialMedia: parseSocialMediaData,
  alert: parseAlertData,
  menuitemGroup: parseMenuItemGroupData,
  banner: parseBannerData,
  statsContainer: parseStatsContainerData,
  imageAndItem: parseImageAndItemData,
  itemItemWithHeading: parseitemItemWithHeadingData,
  accordionContainer: parseAccordionData,
  homeHeroBanner: parseHomeHeroBannerData,
  tab: parseTabData,
  contentGrid: parseContentGridData,
  bioPage: parseBioPage,
  carouselContainer: parseCarouselData,
  people: parsePeoplePageData,
  testimonialBlock: parseTestimonialBlock,
  quote: parseQuoteData,
  textCard: parseTextCard,
  textAndImage: parseTextAndItem,
  navigationLink: parseNavigationLink,
  hubspotForm: parseHubSpotFormData,
  socialMediaCarousel: parseFbAndInstaData,
  shareBlock: parseShareBlockData,
  donationsCta: parseDonateCta,
  findCare: parseFindCareData,
  requestService: parseRequestService,
  defaultErrorPage: parse404Data
};

export function parseComponentData(
  contentTypeID: string,
  rawData: { [key: string]: unknown }
) {
  try {
    const componentData = invoke(
      componentDataPareserMap,
      contentTypeID,
      rawData
    );

    return componentData;
  } catch (error) {
    console.log(`parseComponentData error for ${contentTypeID} ::: `, error);
  }
}

export function parseTextAndItemData(rawData: any): TextAndItemProps | null {
  if (!rawData) {
    return null;
  }

  const textAndItemData: TextAndItemProps = {
    heading: rawData?.fields?.heading?.trim(),
    item: rawData?.fields?.item,
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    text: rawData?.fields?.text?.trim(),
    textPosition: rawData?.fields?.textPosition?.toLowerCase(),
    textAlignment: rawData?.fields?.textAlignment?.toLowerCase(),
    sys: rawData?.sys,
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase()
  };

  return textAndItemData;
}

export function parseTextBlockData(rawData: any): TextBlockProps | null {
  if (!rawData) {
    return null;
  }

  const textBlockProps: TextBlockProps = {
    heading: rawData?.fields?.heading?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    text: rawData?.fields?.text,
    subHeading: rawData?.fields?.subHeading?.trim(),
    alignment: rawData?.fields?.alignment,
    sys: rawData?.sys,
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase(),
    background: parseCmsImage(rawData?.fields?.backgroundImage)
  };

  return textBlockProps;
}

export function parseCmsImage(asset?: any): CmsImageProps | null {
  if (!asset) {
    return null;
  }

  const cmsData = asset?.fields as any;
  return {
    imagelarge: cmsData?.imageLarge?.fields?.file?.url ?? '',
    imagemedium: cmsData?.imageMedium?.fields?.file?.url ?? '',
    imagesmall: cmsData?.imageSmall?.fields?.file?.url ?? '',
    altText: cmsData?.altText?.trim() ?? 'image-alt',
    ariaLabel: cmsData?.ariaLabel?.trim() ?? 'image-aria',
    hoverstatelarge: cmsData?.hoverStateLarge?.fields?.file?.url ?? '',
    hoverstatemedium: cmsData?.hoverStateMedium?.fields?.file?.url ?? '',
    hoverstatesmall: cmsData?.hoverStateSmall?.fields?.file?.url ?? '',
    contentType: cmsData?.imageLarge?.file?.contentType ?? ''
  };
}

export function parseCtaData(rawData: any): CtaButtonProps | null {
  if (!rawData) {
    return null;
  }

  const CtaProps: CtaButtonProps = {
    // urlReference: rawData?.fields?.urlReference,
    target: rawData?.fields?.target?.toLowerCase(),
    buttonType: rawData?.fields?.buttonType?.toLowerCase(),
    buttonSize: rawData?.fields?.buttonSize?.toLowerCase(),
    icon: parseCmsImage(rawData?.fields?.icon),
    iconPosition: rawData?.fields?.iconPosition?.toLowerCase(),
    buttonText: rawData?.fields?.buttonText?.trim(),
    ariaLabel: rawData?.fields?.ariaLabel?.trim(),
    url: rawData?.fields?.url?.trim(),
    file: rawData?.fields?.file?.fields?.file,
    sys: rawData?.sys,
    htmlId: rawData?.fields?.htmlId,
    bgColor: rawData?.fields?.bgColor
  };

  return CtaProps;
}

export function parseSocialMediaData(rawData: any): SocialMediaProps | null {
  if (!rawData) {
    return null;
  }

  const SocialMediaProps: SocialMediaProps = {
    icon: parseCmsImage(rawData?.fields?.icon),
    ariaLabel: rawData?.fields?.ariaLabel?.trim(),
    link: rawData?.fields?.link,
    sys: rawData?.sys,
    platformName: rawData?.fields?.platformName?.toLowerCase()
  };

  return SocialMediaProps;
}

export function parseFindCareData(rawData: any): FindCareProps | null {
  if (!rawData) {
    return null;
  }

  const findCareProps: FindCareProps = {
    buttonAriaLabel: rawData?.fields?.buttonAriaLabel?.trim(),
    buttonIcon: parseCmsImage(rawData?.fields?.buttonIcon),
    buttonText: rawData?.fields?.buttonText?.trim(),
    internalName: rawData?.fields?.internalName,
    searchInputAriaLabel: rawData?.fields?.searchInputAriaLabel?.trim(),
    searchLabel: rawData?.fields?.searchLabel?.trim(),
    services: rawData?.fields?.services,
    defaultService: rawData?.fields?.defaultService?.fields,
    typeOfCareAriaLabel: rawData?.fields?.typeOfCareAriaLabel?.trim(),
    typeOfCareLabel: rawData?.fields?.typeOfCareLabel?.trim(),
    id: rawData?.sys?.id
  };

  return findCareProps;
}

export function parseMetaDataData(rawData: any): any {
  if (!rawData) {
    return null;
  }
  const favIcon = parseCmsImage(rawData?.fields?.favIcon);
  const ogImge = parseCmsImage(
    rawData?.fields?.ogIImage ?? rawData?.fields?.ogImage
  );
  const metaData: Metadata = {
    title: rawData?.fields?.pageTitle,
    description: rawData?.fields?.metaDescription,
    icons: favIcon?.imagelarge
      ? `https:${favIcon?.imagelarge}`
      : '/favicon.ico',
    alternates: {
      canonical: rawData?.fields?.canonicalUrl
    },
    openGraph: {
      images: `https:${ogImge?.imagelarge}`,
      title: rawData?.fields?.ogTitle,
      description: rawData?.fields?.ogDescription
    },
    robots:
      rawData?.fields?.indexable === false
        ? 'noindex, nofollow'
        : 'index, follow'
  };
  return metaData;
}

export function parseAlertData(rawData: any): AlertProps | null {
  if (!rawData) {
    return null;
  }
  const alertData: AlertProps = {
    text: rawData?.fields?.text?.trim(),
    expirationDate: rawData?.fields?.expirationDate,
    internalName: rawData?.fields?.internalName,
    cta: parseCtaData(rawData?.fields?.cta),
    sys: rawData?.sys
  };

  return alertData;
}

export function parseSeparatorBlockData(
  rawData: any
): SeperatorBlockProps | null {
  if (!rawData) {
    return null;
  }

  const SeperatorBlockProps: SeperatorBlockProps = {
    lineWidth: rawData?.fields?.lineWidth,
    sys: rawData?.sys
  };

  return SeperatorBlockProps;
}

export function parseMenuItemGroupData(
  rawData: any
): MenuItemGroupProps | null {
  if (!rawData) {
    return null;
  }
  const MenuItemGroupProps: MenuItemGroupProps = {
    childItems: rawData?.fields?.childItems
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseNavigationLink(item)),
    sectionTitle: rawData?.fields?.sectionTitle?.trim(),
    sys: rawData?.sys
  };

  return MenuItemGroupProps;
}

export function parseBackgroundImage(
  asset?: Asset<undefined, string> | { sys: AssetLink }
): ContentImage | null {
  if (!asset) {
    return null;
  }

  if (!('fields' in asset)) {
    return null;
  }

  return {
    src: asset?.fields?.file?.url || '',
    alt: asset?.fields?.description?.trim() || '',
    width: asset?.fields?.file?.details?.image?.width || 0,
    height: asset?.fields?.file?.details?.image?.height || 0,
    sysid: asset?.sys?.id,
    title: asset?.fields?.title?.trim(),
    contentType: asset?.fields?.file?.contentType,
    fileName: asset?.fields?.file?.fileName
  };
}

export function parseBannerData(rawData: {
  [key: string]: any;
}): BannerProps | null {
  if (!rawData) {
    return null;
  }

  const props: BannerProps = {
    heading: rawData?.fields?.heading?.trim(),
    internalName: rawData?.fields?.internalName,
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase(),
    contentAlignment: rawData?.fields?.contentAlignment?.toLowerCase(),
    cssClass: rawData?.fields?.cssClass,
    findCareComponent: parseFindCareData(rawData?.fields?.findCareComponent),
    findCareComponentAlignment:
      rawData?.fields?.findcarecomponentalignment?.toLowerCase(),
    text: rawData?.fields?.text?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    textColor: rawData?.fields?.textColor?.toLowerCase(),
    // imagePosition: rawData?.fields?.imagePosition?.toLowerCase(),
    subHeading: rawData?.fields?.subheading?.trim(),
    backgroundImage: parseCmsImage(rawData?.fields?.backgroundImage),
    cta: rawData?.fields?.cta
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => {
        return String(item?.sys?.contentType?.sys?.id).toLowerCase() ===
          'donationscta'
          ? parseDonateCta(item)
          : parseCtaData(item);
      }),

    form: rawData?.fields?.form,
    image: parseCmsImage(rawData?.fields?.image),
    sys: rawData?.sys
  };
  return props;
}

export function parsePrimaryNavigation(
  asset?: any
): PrimaryNavigationProps | null {
  if (!asset) {
    return null;
  }

  if (!('fields' in asset)) {
    return null;
  }
  return {
    commonLogo: {
      altText: asset?.fields?.commonLogo?.fields?.altText?.trim(),
      ariaLabel: asset?.fields?.commonLogo?.fields?.ariaLabel?.trim(),
      internalName: asset?.fields?.commonLogo?.fields?.internalName,
      icon: asset?.fields?.commonLogo?.fields?.icon,
      iconPosition: asset?.fields?.commonLogo?.fields?.iconPosition,
      imageLarge: {
        file: asset?.fields?.commonLogo?.fields?.imageLarge?.fields?.file
      }
    },
    homePageLogo: {
      altText: asset?.fields?.homepageLogo?.fields?.altText?.trim(),
      ariaLabel: asset?.fields?.homepageLogo?.fields?.ariaLabel?.trim(),
      internalName: asset?.fields?.homepageLogo?.fields?.internalName,
      icon: asset?.fields?.homepageLogo?.fields?.icon,
      iconPosition: asset?.fields?.homepageLogo?.fields?.iconPosition,
      imageLarge: {
        file: asset?.fields?.homepageLogo?.fields?.imageLarge?.fields?.file
      }
    },
    menuItems: asset?.fields?.menuItems
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseNavigationLink(item)),
    imageLink: asset?.fields?.imageLink?.trim()
  };
}

export function parseNavigationLink(rawData?: any): NavigationLinkProps | null {
  if (!rawData) {
    return null;
  }

  if (!('fields' in rawData)) {
    return null;
  }

  const text = rawData?.fields?.text?.trim();
  let url = rawData?.fields?.url?.trim();
  /* The  code snippet is checking if either the `text` or `url` variables contain a
valid US phone number using the `isValidUSPhoneNumber` function. If at least one of them contains a
valid US phone number and the `url` variable does not already start with 'tel:', then it appends
'tel:' to the beginning of the `url` variable. If the `url` variable is null or undefined, it uses
the `text` variable instead. */
  if (
    (isValidUSPhoneNumber(text) || isValidUSPhoneNumber(url)) &&
    !String(url).includes('tel:')
  ) {
    url = `tel:${String(url ?? text)}`;
  }

  return {
    text,
    url: url ? url : ':',
    target: String(rawData?.fields?.target)
      ?.toLowerCase()
      ?.trim()
      ?.replace(/ /g, ''),
    ariaLabel: rawData?.fields?.ariaLabel?.trim(),
    icon: parseCmsImage(rawData?.fields?.icon),
    iconPosition: rawData?.fields?.iconPosition?.toLowerCase(),
    className: rawData?.fields?.className,
    sysId: rawData?.sys?.contentType?.sys?.id,
    dropdown: rawData?.fields?.dropdownItems
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseNavigationLink(item)),
    type: String(rawData?.fields?.type)
      ?.toLowerCase()
      ?.trim()
      ?.replace(/ /g, '')
  };
}

export function parseTopNavigation(rawData?: any): TopNavigationProps | null {
  if (!rawData) {
    return null;
  }

  if (!('fields' in rawData)) {
    return null;
  }

  return {
    alert: parseAlertData(rawData?.fields?.alert),
    menuItems: rawData?.fields?.menuItems
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseNavigationLink(item)),
    search: parseCtaData(rawData?.fields?.search),
    sysId: rawData?.sys?.contentType?.sys?.id,
    floatingLinks: rawData?.fields?.floatingLinks
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseNavigationLink(item))
  };
}

export function parseHeaderNavigatonData(
  rawData: any
): NavigationHeaderProps | any {
  if (!rawData) {
    return null;
  }

  return {
    primaryNavigation: parsePrimaryNavigation(rawData?.primaryNavigation),
    topNavigation: parseTopNavigation(rawData?.topNavigation)
  };
}

export function parseImageAndItemData(rawData: any): ImageAndItemProps | null {
  if (!rawData) {
    return null;
  }

  const ImageAndItemProps: ImageAndItemProps = {
    internalName: rawData?.fields?.internalName,
    heading: rawData?.fields?.heading?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    subHeading: rawData?.fields?.subheading?.trim(),
    text: rawData?.fields?.text?.trim(),
    media: rawData?.fields?.media?.fields?.videoType
      ? parseCmsVideo(rawData?.fields?.media)
      : parseCmsImage(rawData?.fields?.media),
    imagePosition: rawData?.fields?.imagePosition?.toLowerCase(),
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase(),
    item: rawData?.fields?.item,
    sys: rawData?.sys,
    verticalAlignment: rawData?.fields?.verticalAlignment?.toLowerCase()
  };

  return ImageAndItemProps;
}

export function parseitemItemWithHeadingData(
  rawData: any
): ItemItemHeadingProps | null {
  if (!rawData) {
    return null;
  }

  return {
    alignment: rawData?.fields?.alignment?.toLowerCase(),
    heading: rawData?.fields?.heading?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    internalName: rawData?.fields?.internalName,
    subHeading: rawData?.fields?.subheading?.trim(),
    verticalSeparator: rawData?.fields?.verticalSeparator?.toLowerCase(),
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase(),
    itemLeft: rawData?.fields?.itemLeft,
    itemRight: rawData?.fields?.itemRight,
    sysId: rawData?.sys?.id,
    itemLeftAlignment: rawData?.fields?.itemLeftAlignment?.toLowerCase(),
    itemRightAlignment: rawData?.fields?.itemRightAlignment?.toLowerCase()
  };
}

export function parseAccordionData(
  rawData: any
): AccordionContainerProps | null {
  if (!rawData) {
    return null;
  }

  const AccordionContainerData: AccordionContainerProps = {
    internalName: rawData?.fields?.internalName,
    heading: rawData?.fields?.heading?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    accordionItems: parseAccordionItemData(
      rawData?.fields?.accordionItems,
      rawData?.fields?.behavior?.toLowerCase(),
      rawData?.fields?.contentAlignment?.toLowerCase()
    ),
    contentAlignment: rawData?.fields?.contentAlignment?.toLowerCase(),
    behavior: rawData?.fields?.behavior?.toLowerCase(),
    theme: rawData?.fields?.theme?.toLowerCase(),
    sys: rawData?.sys,
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase()
  };

  return AccordionContainerData;
}

export function parseAccordionItemData(
  rawData: unknown,
  behavior: AccordionBehavior,
  contentAlignment?: AccordionAlignment
): AccordionItem[] | [] {
  if (!rawData) {
    return [];
  }

  const isVerticalTab = contentAlignment === 'right';
  const items: AccordionItem[] = (rawData as [])
    ?.filter((element: any) => element?.fields)
    ?.map((data: { [key: string]: any }, index) => {
      let flag = false;
      const firstOpen = index === 0 && behavior === 'first open';
      const firstOpenVertical = isVerticalTab && index === 0;
      if (firstOpen || firstOpenVertical) {
        flag = true;
      } else if (behavior === 'all open' && !isVerticalTab) {
        flag = true;
      }
      const AccordionContainerData: AccordionItem = {
        internalName: data?.fields?.internalName,
        heading: data?.fields?.heading?.trim(),
        content: data?.fields?.content,
        icon: parseCmsImage(data?.fields?.icon),
        sys: data?.sys,
        isExpanded: flag,
        id: `${uuidv4()}-${index}`
      };
      return AccordionContainerData;
    });

  return items;
}

export function parseHomeHeroBannerData(
  rawData: any
): HomeHeroBannerProps | null {
  if (!rawData) {
    return null;
  }
  const HomeHeroBannerProps: HomeHeroBannerProps = {
    internalName: rawData?.fields?.internalName,
    backgroundVideo: parseCmsVideo(rawData?.fields?.backgroundVideo),
    heading: rawData?.fields?.heading?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    subHeading: rawData?.fields?.subHeading?.trim(),
    text: rawData?.fields?.text?.trim(),
    cta: rawData?.fields?.cta
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseCtaData(item)),
    popupLinks: rawData?.fields?.popupLinks
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseNavigationLink(item)),
    sys: rawData?.sys
  };

  return HomeHeroBannerProps;
}

export function parseStatsContainerData(
  rawData: any
): StatsContainerProps | null {
  if (!rawData) {
    return null;
  }
  const StatsContainerProps: StatsContainerProps = {
    statistics: parseStatisticsData(rawData?.fields?.statistics),
    heading: rawData?.fields?.heading?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    alignment: rawData?.fields?.alignment?.toLowerCase(),
    columns: rawData?.fields?.columns,
    style: rawData?.fields?.style?.toLowerCase(),
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase(),
    sys: rawData?.sys
  };

  return StatsContainerProps;
}

export function parseStatisticsData(rawData: any): LabelAndValueProps[] | null {
  if (!rawData) {
    return null;
  }

  const labelAndValueProps = rawData
    ?.filter((element: any) => element?.fields)
    ?.map((service: any) => {
      return {
        label: service?.fields?.label,
        value: service?.fields?.value
      };
    });

  return labelAndValueProps;
}

export function parseTabData(rawData: any): CmsTabProps | null {
  if (!rawData) {
    return null;
  }

  const result: CmsTabProps = {
    tabGroupTitle: rawData?.fields?.tabGroupTitle?.trim(),
    tabItems: rawData?.fields?.tabItems
      ?.filter((element: any) => element?.fields)
      ?.map((item: TabItemProps) => parseIndividualTabItemData(item)),
    position: rawData?.fields?.position?.toLowerCase(),
    tabStyle: rawData?.fields?.tabStyle?.toLowerCase(),
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase(),
    sys: rawData?.sys
  };

  return result;
}

export function parseIndividualTabItemData(itemData: any): TabItemProps | null {
  if (!itemData) {
    return null;
  }

  const result: TabItemProps = {
    tabLabel: itemData?.fields?.tabLabel?.trim(),
    icon: parseCmsImage(itemData?.fields?.icon),
    iconPosition: String(itemData?.fields?.iconPosition)?.toLowerCase().trim(),
    content: itemData?.fields?.content,
    contentPosition: String(itemData?.fields?.contentPosition)
      .toLowerCase()
      .trim(),
    sys: itemData?.sys
  };

  return result;
}

export function parseBioPage(rawData: any): BioPageProps | null {
  if (!rawData) {
    return null;
  }
  const BioPageData: BioPageProps = {
    firstName: rawData?.fields?.firstName?.trim(),
    lastName: rawData?.fields?.lastName?.trim(),
    designationTitle: rawData?.fields?.designationTitle?.trim(),
    designation: rawData?.fields?.designation?.trim(),
    image: parseCmsImage(rawData?.fields?.image),
    imageThumbnail: parseCmsImage(rawData?.fields?.imageThumbnail),
    bioDetails: rawData?.fields?.bioDetails
      ?.filter((element: any) => element?.fields)
      ?.map((bioItem: any) => parseBioDetails(bioItem)),
    overview: rawData?.fields?.overview?.trim(),
    sys: rawData?.sys
  };

  return BioPageData;
}

function parseBioDetails(bioData: any) {
  if (!('fields' in bioData)) {
    return null;
  }
  return {
    heading: bioData?.fields?.heading?.trim(),
    text: bioData?.fields?.text
  };
}

export function parseCarouselData(rawData: any): CarouselContainerProps | null {
  if (!rawData) {
    return null;
  }
  const isTestimonial =
    rawData?.fields?.carouselType?.toLowerCase() === 'testimonials';
  const testimonialItems = rawData?.fields?.carouselItems?.filter(
    (item: any) => item?.sys?.contentType?.sys?.id === 'quote'
  );
  const nonTestimonialItems = rawData?.fields?.carouselItems?.filter(
    (item: any) => item?.sys?.contentType?.sys?.id !== 'quote'
  );

  const carouselData: CarouselContainerProps = {
    heading: rawData?.fields?.heading?.trim(),
    subHeading: rawData?.fields?.subHeading?.trim(),
    alignment: rawData?.fields?.alignment?.toLowerCase(),
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase(),
    carouselType: rawData?.fields?.carouselType?.toLowerCase(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    internalName: rawData?.fields?.internalName,
    text: rawData?.fields?.text,
    richText: rawData?.fields?.richText,
    cta: parseCtaData(rawData?.fields?.cta),
    image: parseCmsImage(rawData?.fields?.image),
    ctaPosition: rawData?.fields?.ctaPosition?.toLowerCase(),
    carouselItem: isTestimonial
      ? testimonialItems
          ?.filter((element: any) => element?.fields)
          ?.map((item: any) => parseQuoteData(item))
      : nonTestimonialItems
          ?.filter((element: any) => element?.fields)
          ?.map((item: any) => parseCarouselItem(item))
  };
  return carouselData;
}

export function parseCarouselItem(rawData: any): CarouselItem | null {
  if (!rawData) {
    return null;
  }

  const CarouselItem: CarouselItem = {
    id: uuidv4(),
    image: parseCmsImage(rawData?.fields?.image),
    cta: parseCtaData(rawData?.fields?.cta),
    heading: rawData?.fields?.heading?.trim(),
    internalName: rawData?.fields?.internalName,
    title: rawData?.fields?.title?.trim(),
    text: rawData?.fields?.text?.trim(),
    sys: rawData?.sys,
    cardGroup: rawData?.fields?.cardGroup
  };

  return CarouselItem;
}

export function parseContentGridData(rawData: any): ContentGridProps | null {
  if (!rawData) {
    return null;
  }
  const result: ContentGridProps = {
    items: rawData?.fields?.items,
    columns: rawData?.fields?.columns,
    columnsMobileView: rawData?.fields?.columnsMobileView ?? '1',
    columnsTabletView: rawData?.fields?.columnsTabletView ?? '2',
    spacing: rawData?.fields?.spacing?.toLowerCase() ?? 'normal',
    heading: rawData?.fields?.heading?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    headingAlignment: rawData?.fields?.headingAlignment?.toLowerCase(),
    itemArrangementType: rawData?.fields?.itemArrangementType?.toLowerCase(),
    itemAlignment: rawData?.fields?.itemAlignment?.toLowerCase(),
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase(),
    sys: rawData?.sys
  };

  return result;
}

export function parseCmsVideo(rawData: any): CmsVideoProps | null {
  if (!rawData) {
    return null;
  }
  const CmsVideoProps: CmsVideoProps = {
    internalName: rawData?.fields?.internalName,
    autoPlay: rawData?.fields?.autoPlay,
    videoId: rawData?.fields?.videoId,
    videoUrl: rawData?.fields?.videoUrl?.trim(),
    description: rawData?.fields?.description?.trim(),
    videoType: rawData?.fields?.videoType?.toLowerCase(),
    placeholderImage: parseCmsImage(rawData?.fields?.placeholderImage),
    media: rawData?.fields?.media?.fields?.file,
    sysId: rawData?.sys?.id
  };
  return CmsVideoProps;
}
export function parsePeoplePageData(rawData: any): PeoplePageProps | null {
  if (!rawData) {
    return null;
  }

  const PeoplePageProps: PeoplePageProps = {
    internalName: rawData?.fields?.statistics,
    heading: rawData?.fields?.heading?.trim(),
    subHeading: rawData?.fields?.subheading?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    alignment: rawData?.fields?.alignment?.toLowerCase(),
    sysId: rawData?.sys?.id,
    peopleItem: rawData?.fields?.peopleItem
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => PeopleItemParser(item)),
    backGroundColor: rawData?.fields?.backgroundColour?.toLowerCase()
  };

  return PeoplePageProps;
}

export function PeopleItemParser(rawData: any): PeopleItemProps | null {
  if (!rawData) {
    return null;
  }

  const peopleParserData: PeopleItemProps = {
    sysId: rawData?.sys?.id,
    designation: rawData?.fields?.designation?.trim(),
    designationTitle: rawData?.fields?.designationTitle?.trim(),
    firstName: rawData?.fields?.firstName?.trim(),
    lastName: rawData?.fields?.lastName?.trim(),
    image: parseCmsImage(rawData?.fields?.imageThumbnail),
    slug: rawData?.fields?.slug
  };

  return peopleParserData;
}

export function parseTestimonialBlock(rawData: any): TestimonialProps | null {
  if (!rawData) {
    return null;
  }
  const TestimonialProps: TestimonialProps = {
    heading: rawData?.fields?.heading?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    alignment: rawData?.fields?.alignment?.toLowerCase(),
    backgroundColor: rawData?.fields?.backgroundColor?.toLowerCase(),
    quoteReference: rawData?.fields?.quoteReference?.fields,
    internalName: rawData?.fields?.internalName,
    sysId: rawData?.sys?.id
  };

  return TestimonialProps;
}

export function parseQuoteData(rawData: any): QuoteProps | null {
  if (!rawData) {
    return null;
  }

  const QuoteProps: QuoteProps = {
    id: uuidv4(),
    author: rawData?.fields?.author?.trim(),
    designation: rawData?.fields?.designation?.trim(),
    text: rawData?.fields?.text,
    image: parseCmsImage(rawData?.fields.image),
    sysId: rawData?.sys?.id,
    internalName: rawData?.fields?.internalName
  };

  return QuoteProps;
}

export function parseTextCard(rawData: any): TextCardProps | null {
  if (!rawData) {
    return null;
  }
  const TextCardData: TextCardProps = {
    heading: rawData?.fields?.heading?.trim(),
    headingValue: rawData?.fields?.headingValue?.toLowerCase(),
    text: rawData?.fields?.text?.trim(),
    textValue: rawData?.fields?.textValue?.toLowerCase(),
    alignment: rawData?.fields?.alignment?.toLowerCase(),
    appearance: rawData?.fields?.appearance?.toLowerCase(),
    backgroundColor: rawData?.fields?.backgroundColour?.toLowerCase(),
    internalName: rawData?.fields?.internalName,
    cta: parseCtaData(rawData?.fields?.cta),
    ctaPosition: rawData?.fields?.ctaPosition?.toLowerCase(),
    image: parseCmsImage(rawData?.fields?.image),
    imagePosition: rawData?.fields?.imagePosition?.toLowerCase(),
    richText: rawData?.fields?.richText,
    sys: rawData?.sys,
    imageSize: rawData?.fields?.imageSize?.toLowerCase()
  };

  return TextCardData;
}

export function parseFooterData(rawData: any): FooterPageProps | null {
  if (!rawData) {
    return null;
  }

  const footerParsedData: FooterPageProps = {
    internalName: rawData?.fields?.internalName,
    logo: parseCmsImage(rawData?.fields?.logo?.fields?.icon),
    logoLinkUrl: rawData?.fields?.logo?.fields?.url?.trim(),
    logoLinkAriaLabel: rawData?.fields?.logo?.fields?.ariaLabel?.trim(),
    text: rawData?.fields?.text?.trim(),
    menuItemGroup: rawData?.fields?.menuItemGroup
      ?.filter((element: any) => element?.fields)
      ?.map((item: MenuItemGroupProps) => parseMenuItemGroupData(item)),
    socialMediaIcons: rawData?.fields?.socialMediaIcons
      ?.filter((element: any) => element?.fields)
      ?.map((item: SocialMediaProps) => parseSocialMediaData(item)),
    form: rawData?.fields?.form?.fields,
    accreditationImage: parseCmsImage(rawData?.fields?.accreditationImage),
    copyrightText: rawData?.fields?.copyrightText,
    sysId: rawData?.sys?.id
  };

  return footerParsedData;
}

export function parseTextAndItem(rawData: any): TextAndImageProps | null {
  if (!rawData) {
    return null;
  }
  const TextAndImageProps: TextAndImageProps = {
    text: rawData?.fields?.text?.trim(),
    textSize: rawData?.fields?.textSize?.toLowerCase(),
    image: parseCmsImage(rawData?.fields?.image),
    iconPosition: rawData?.fields?.iconPosition?.toLowerCase(),
    bold: rawData?.fields?.bold
  };

  return TextAndImageProps;
}

export function parseHubSpotFormData(rawData?: any): HubSpotFormProps | null {
  if (!rawData) {
    return null;
  }

  if (!('fields' in rawData)) {
    return null;
  }

  return {
    formId: String(rawData?.fields?.formId),
    id: `hubspot-form-${String(rawData?.fields?.formId)}`
  };
}

export function parseFbAndInstaData(rawData?: any): FbAndInstaData {
  if (!rawData) {
    return null;
  }

  const fbData = rawData?.fields?.socialMediaType?.find(
    (item: any) => item?.fields?.platformName?.toLowerCase() === 'facebook'
  );
  const instaData = rawData?.fields?.socialMediaType?.find(
    (item: any) => item?.fields?.platformName?.toLowerCase() === 'instagram'
  );

  const carouselItem: CarouselData = {
    internalName: rawData?.fields?.internalName,
    carouselItemCount: rawData?.fields?.carouselItemCount,
    hashtags: rawData?.fields?.hashtags
      ? rawData?.fields?.hashtags.split(',')
      : []
  };
  const insta: InstaData = {
    instaToken: instaData?.fields?.accessToken,
    instaPlatformName: instaData?.fields?.platformName,
    instaIcon: parseCmsImage(instaData?.fields?.icon),
    pageId: instaData?.fields?.pageId
  };

  const fb: FbData = {
    fbToken: fbData?.fields?.accessToken,
    pageId: fbData?.fields?.pageId,
    fbPlatformName: fbData?.fields?.platformName,
    fbIcon: parseCmsImage(fbData?.fields?.icon)
  };
  return { instaData: insta, fbData: fb, carouselItemNo: carouselItem };
}

export function parseShareBlockData(rawData?: any) {
  if (!rawData) return null;

  const data: ShareBlockProps = {
    internalName: rawData?.fields?.internalName,
    title: rawData?.fields?.title?.trim(),
    shareList: rawData?.fields?.shareList
      ?.filter((element: any) => element?.fields)
      ?.map((item: SocialMediaProps) => parseSocialMediaData(item))
  };

  return data;
}

export function parseDonateCta(rawData?: any): DonateCtaProps | null {
  if (!rawData) return null;

  const data: DonateCtaProps = {
    buttonText: rawData?.fields?.buttonText?.trim(),
    formId: rawData?.fields?.formId?.trim(),
    formType: rawData?.fields?.formType?.trim(),
    source: rawData?.fields?.source?.trim(),
    sys: rawData?.sys
  };

  return data;
}

export function parse404Data(rawData: any): NotFoundProps | null {
  if (!rawData) {
    return null;
  }
  const NotFoundProps: NotFoundProps = {
    internalName: rawData?.fields?.internalName,
    heading: rawData?.fields?.heading?.trim(),
    text: rawData?.fields?.text?.trim(),
    cta: parseCtaData(rawData?.fields?.cta)
  };

  return NotFoundProps;
}

export function parseOfficeData(rawData: any): findAnOfficePageProps | null {
  if (!rawData) {
    return null;
  }

  const officePageProps = {
    getDirectionsLabel: rawData?.fields?.getDirectionsLabel,
    internalName: rawData?.fields?.internalName,
    locationInputAriaLabel: rawData?.fields?.locationInputAriaLabel,
    locationLabel: rawData?.fields?.locationLabel,
    radiusInMilesOptions: rawData?.fields?.radiusInMilesOptions,
    radiusLabel: rawData?.fields?.radiusLabel,
    searchButtonLabel: rawData?.fields?.searchButtonLabel,
    serviceTypeLabel: rawData?.fields?.serviceTypeLabel,
    services: parseServiceData(rawData?.fields?.services),
    serviceAreasLabel: rawData?.fields?.serviceAreasLabel,
    servicesOfferedLabel: rawData?.fields?.servicesOfferedLabel,
    visitOfficeButtonText: rawData?.fields?.visitOfficeButtonText,
    noLocationsFound: rawData?.fields?.noLocationsFound,
    shareViaEmailLabel: rawData?.fields?.shareViaEmailLabel,
    locationInputLabel: rawData?.fields?.locationInputLabel,
    findOfficeButtonLabel: rawData?.fields?.findOfficeButtonLabel,
    findOfficeButtonAriaLabel: rawData?.fields?.findOfficeButtonAriaLabel,
    selectServiceHelptext: rawData?.fields?.selectServiceHelptext,
    defaultRadius: rawData?.fields?.defaultRadius
  };

  return officePageProps;
}

export function parseServiceData(rawData: any): serviceDataProps[] | null {
  if (!rawData) {
    return null;
  }

  const serviceProps = rawData
    ?.filter((element: any) => element?.fields)
    ?.map((service: any) => {
      return {
        fullName: service?.fields?.fullName,
        masterDataId: service?.fields?.masterDataId,
        shortName: service?.fields?.shortName,
        icon: parseCmsImage(service?.fields?.icon),
        description: service?.fields?.description,
        isSelected: false
      };
    });

  return serviceProps;
}

export function parseRequestService(rawData: any): RequestServiceProps | null {
  if (!rawData) {
    return null;
  }
  const RequestServiceProps: RequestServiceProps = {
    internalName: rawData?.fields?.internalName,
    requestServiceLabel: rawData?.fields?.requestServiceLabel?.trim(),
    requestServiceHeadingValue: rawData?.fields?.requestServiceHeadingValue,
    searchLabel: rawData?.fields?.searchLabel?.trim(),
    buttonText: rawData?.fields?.buttonText?.trim(),
    service: rawData?.fields?.service?.fields,
    searchInputAriaLabel: rawData?.fields?.searchInputAriaLabel?.trim()
  };

  return RequestServiceProps;
}

export function parseSearchTextData(rawData: any) {
  if (!rawData) {
    return null;
  }
  const headerValue =
    rawData?.fields?.templateReference.fields?.additionalComponents;
  const headerText = headerValue
    ?.filter((element: any) => element?.fields)
    ?.map((item: any) => {
      if (item?.sys?.contentType?.sys?.id === 'siteSearch') {
        const value = item?.fields?.headerText;
        return value;
      }
    });
  return headerText;
}

export function parseOfficePageData(rawData: any): OfficePageProps | null {
  if (!rawData) {
    return null;
  }

  const OfficePageDataProps: OfficePageProps | null = {
    internalName: rawData?.internalName,
    service: rawData?.service?.fields,
    backgroundImage: parseCmsImage(rawData?.backgroundImage),
    form: rawData?.form?.fields,
    getDirectionsLabel: rawData?.getDirectionsLabel,
    aboutUsLabel: rawData?.aboutUsLabel,
    aboutContent: rawData?.aboutContent,
    accreditationList: rawData?.accreditationList
      ?.filter((element: any) => element?.fields)
      ?.map((item: any) => parseCmsImage(item)),
    moreInformationLabel: rawData?.moreInformationLabel,
    directorLabel: rawData?.directorLabel,
    aboutBayadaLabel: rawData?.aboutBayadaLabel,
    aboutBayadaText: rawData?.aboutBayadaText,
    serviceAreasLabel: rawData?.serviceAreasLabel,
    servicesOfferedLabel: rawData?.servicesOfferedLabel,
    visitOfficeButtonText: rawData?.visitOfficeButtonText,
    officeTimings: rawData?.officeTimings,
    officesNearLabel: rawData?.officesNearLabel,
    shareViaEmailLabel: rawData?.shareViaEmailLabel,
    bannerDescription: rawData?.bannerDescription,
    contactUsContent: rawData?.contactUsContent,
    formHeader: rawData?.formHeader,
    companyName: rawData?.companyName,
    abbreviation: rawData?.abbreviation
  };
  return OfficePageDataProps;
}

export function parseServicesData(rowData: any): ServiceProps | null {
  if (!rowData) {
    return null;
  }
  const service: ServiceProps = {
    fullName: rowData?.fields?.fullName?.trim(),
    masterDataId: rowData?.fields?.masterDataId,
    icon: parseCmsImage(rowData?.fields?.icon),
    shortName: rowData?.fields?.shortName,
    sys: rowData?.sys,
    entryId: rowData?.sys?.id,
    priorityOrder: rowData?.fields?.priorityOrder
  };
  return service;
}
